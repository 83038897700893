import styled from 'styled-components';

const VideoStyle = styled.div`
	--videoPadTop: 50%;

	--playPauseButtonSize: var(--sp16x);

	&.video-cont {
		position: relative;
		width: 100%;
		padding-top: var(--videoPadTop);

		video {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
			z-index: 1;
		}

		.video-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: var(--playPauseButtonSize);
			color: var(--whiteColor);
			z-index: 7;
			cursor: pointer;
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--playPauseButtonSize: var(--sp12x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--playPauseButtonSize: var(--sp11x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--playPauseButtonSize: var(--sp10x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--playPauseButtonSize: var(--sp9x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--playPauseButtonSize: var(--sp6x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--playPauseButtonSize: var(--sp5x);
	}
`;

export default VideoStyle;
