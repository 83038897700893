'use client';

import gsap from 'gsap';
import { ScrollTrigger, SplitText } from 'gsap/all';
import { useGSAP } from '@gsap/react';

//* HOC's
import { withLanguageContext } from '@/context';

const ScrollActive = ({ selectedLang, marker, markerEnd, animTitle, animOpacity, allRefs, stagger, reverse, once, duration, delay, paramEnded, paramStarted, isParamStart, isParam, percentDuration, percent, percentFunction, markerDesktop, children }) => {
	//! Plugins
	gsap.registerPlugin(useGSAP);
	gsap.registerPlugin(SplitText);

	useGSAP(() => {
		gsap.registerPlugin(ScrollTrigger);

		//! Animation title word up
		//default style in react opacity-0
		if (animTitle) {
			const defaultConfig = {
				duration: 0.5,
				stagger: 0.2,
				once: true,
				delay: 0,
			};

			ScrollTrigger.create({
				trigger: allRefs.current,
				start: `${marker || 'center'} 90%`,
				end: `${marker || 'center'} top`,
				// markers: true,
				once: once || defaultConfig.once,

				onEnter: () => {
					const mySplitText = new SplitText(allRefs.current, { type: 'words lines' });

					let getPercent = mySplitText.words.length * (duration || defaultConfig.duration);
					if (percentDuration) {
						getPercent = (percentDuration * getPercent) / 100;
					} else {
						getPercent = (75 * getPercent) / 100;
					}

					gsap.to(allRefs.current, {
						opacity: 1,
						duration: 0,
					}).then(() => {
						if (isParamStart) {
							paramStarted(true);
						}
						if (percent) {
							percentFunction(getPercent);
						}
						gsap.set(mySplitText.words, { y: reverse ? -100 : 100 });
						gsap.set(mySplitText.lines, { overflow: 'hidden' });
						gsap.to(mySplitText.words, {
							y: 0,
							ease: 'power2.out',
							duration: duration || defaultConfig.duration,
							stagger: stagger || defaultConfig.stagger,
							delay: delay || defaultConfig.delay,
						}).then(() => {
							if (isParam) {
								paramEnded(true);
							}
						});
					});
				},
			});
		}
		//! Animation opacity
		//inner css opacity-0
		if (animOpacity) {
			const defaultConfig = {
				duration: 1,
				once: true,
				delay: 0,
			};

			ScrollTrigger.create({
				trigger: allRefs.current,
				// start: 'center 80%',
				// end: 'center top',
				start: `${marker ? marker : 'center'} ${markerDesktop ? markerDesktop : '80%'}`,
				end: `${markerEnd ? markerEnd : 'center'} top`,
				// markers: true,
				once: once || defaultConfig.once,

				onEnter: () => {
					gsap.to(allRefs.current, {
						opacity: 1,
						ease: 'power2.out',
						duration: duration || defaultConfig.duration,
						delay: delay || defaultConfig.delay,
					});
				},
			});
		}
	}, [selectedLang, animOpacity]);

	return children;
};

export default withLanguageContext(ScrollActive, ['selectedLang']);
