import styled from 'styled-components';

const FileSectionStyle = styled.div`
	--fileHeight: 50%;

	.file-container {
		.image-cont,
		.video-cont {
			padding-top: var(--fileHeight);
		}
	}
`;

export default FileSectionStyle;
