import { forwardRef, useRef, useImperativeHandle } from 'react';

//* HOC's
import { withUIContext } from '@/context';

//* Style
import FileSectionStyle from './style';

//* Components
import Image from '../Image';
import Video from '../Video';

const FileSection = forwardRef(({ file, objectFit = 'cover', className, priority = false, double, winWidth, onLoad, isOpacityZero, videoLoop = false }, ref) => {
	//! Ref
	const fileRef = useRef();

	//! Imperative Handle
	useImperativeHandle(ref, () => fileRef.current, []);

	//! Mobile Desktop
	const doubleSrc = double ? (winWidth >= 1280 ? double?.[0]?.src : double?.[1]?.src) : null;
	const doubleType = double ? (winWidth >= 1280 ? double?.[0]?.type : double?.[1]?.type) : null;
	const doubleAlt = double ? (winWidth >= 1280 ? double?.[0]?.alt : double?.[1]?.alt) : null;

	return (
		<FileSectionStyle className={`file-section ${className || ''}`}>
			<div className='file-container'>
				{file?.type === 'image' || doubleType === 'image' ? (
					<Image
						ref={fileRef}
						onLoad={onLoad}
						priority={priority}
						className={objectFit}
						src={file?.src || doubleSrc}
						alt={file?.alt || doubleAlt}
						isOpacityZero={isOpacityZero}
					/>
				) : (
					<Video
						loop={videoLoop}
						ref={fileRef}
						autoPlay={false}
						src={file?.src || doubleSrc}
					/>
				)}
			</div>
		</FileSectionStyle>
	);
});

FileSection.displayName = 'FileSection';

export default withUIContext(FileSection, ['winWidth']);
